import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import SendIcon from "../Icons/SendIcon";
import ClipIcon from "../Icons/ClipIcon";
import LoadingAnswer from "../LoadingAnswer";

import config from "../../config.json";
import "./index.css";

function ChatBot() {
  const [messages, setMessages] = useState([
    {
      id: 1,
      content:
        "Olá! Sou um assistente com tecnologia de IA e posso ajudá-lo com dicas e perguntas.",
      sender: "bot",
    },
    /**{ id: 2, content: "I need help with my order.", sender: "user" }*/
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState("");
  const chatWindowRef = useRef(null);

  const { id } = useParams();
  const [chatData, setChatData] = useState({});

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const response = await fetch(config.api.services + `/chat/get?uid=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) throw new Error("Failed to fetch chat history");

        const responseJSON = await response.json();

        if (responseJSON.data) {
          const data = responseJSON.data;
          setChatData(data);
        }
      } catch (error) {
        console.error("Error fetching chat settings:", error.message);
      }
    };

    fetchChat();
  }, [id]);

  useEffect(() => {
    scrollToEnd();
  }, [messages]);

  const fetchFollowUpMessages = async (
    question_uid,
    maxRetries = 10,
    retryDelay = 4000
  ) => {
    if (maxRetries <= 0) {
      //console.log("Max retries reached, stopping further attempts.");
      return [];
    }

    try {
      const response = await fetch(config.api.services + "/answer/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: {
            uid: question_uid
          }
        })
      });

      if (!response.ok) throw new Error("Failed on follow-up request");

      const data = await response.json();

      // Check if the messages array is empty
      if (data.messages && data.messages.length > 0) {
        return data.messages;
      } else {
        //console.log(`No messages found, retrying in ${retryDelay}ms...`);
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        return fetchFollowUpMessages(question_uid, maxRetries - 1, retryDelay);
      }
    } catch (error) {
      console.error("Error during the follow-up fetch:", error.message);
      return [];
    }
  };

  const handleCreateQuestion = async (question) => {
    try {
      const response = await fetch(config.api.services + "/question/post", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: question, chat_uid: id }),
      });

      if (!response.ok) throw new Error("Failed to submit question");

      let responseData = await response.json();
      responseData = responseData["data"];

      if (responseData.question_uid) {
        const fetchedMessages = await fetchFollowUpMessages(
          responseData.question_uid
        );
        //console.log('Final messages:', fetchedMessages);

        const newBotMessages = fetchedMessages.map((msg, index) => ({
          id: messages.length + 2 + index,
          content: msg.content,
          sender: "bot",
          file: msg.file ? msg.file : [],
        }));

        // Update the messages state with bot's messages
        setMessages((prevMessages) => [...prevMessages, ...newBotMessages]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error during the fetch processes:", error.message);
    }
  };

  const handleSendMessage = () => {
    if (input.trim()) {
      const newMessage = {
        id: messages.length + 1,
        content: input,
        sender: "user",
      };
      setMessages([...messages, newMessage]);
      setIsLoading(true);
      setInput("");

      // Reset textarea size
      const textarea = document.querySelector(".chat-input");
      if (textarea) {
        textarea.style.height = "";
      }

      handleCreateQuestion(newMessage.content);
    }
  };

  const scrollToEnd = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !isLoading) {
      if (!e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    }
  };

  const handleTextAreaResize = (e) => {
    e.target.style.height = "auto";
    const maxHeight = parseInt(
      window.getComputedStyle(e.target).getPropertyValue("max-height")
    );
    e.target.style.height = Math.min(e.target.scrollHeight, maxHeight) + "px";

    e.target.scrollTop = e.target.scrollHeight;
  };

  const getContrastYIQ = (hexcolor) => {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
}

  return (
    <div className={`chat ${chatData?.dark_mode && "chat--dark"}`}>
      <header
        className="chat-header"
        style={{ backgroundColor: chatData?.color }}
      >
        <p
          style={{ color: chatData?.color && getContrastYIQ(chatData?.color) }}
        >
          {chatData["name"]}
        </p>
      </header>
      <div
        className={`chat-content ${
          chatData?.dark_mode && "chat-content--dark"
        }`}
        ref={chatWindowRef}
      >
        {messages.map((message, index) => (
          <div
            className={`chat-message-wrapper ${
              message.sender !== "bot" && "chat-message-wrapper-user"
            }`}
          >
            {message.sender === "bot" && (
              <img
                style={{ border: `2px solid ${chatData?.color}` }}
                src="avatars/bot.jpg"
                alt="AI Face"
              />
            )}
            <div
              key={index}
              style={
                message.sender === "bot"
                  ? {
                      color: getContrastYIQ(
                        chatData?.dark_mode ? "#222222" : "#ffffff"
                      ),
                    }
                  : {
                      color: chatData?.color && getContrastYIQ(chatData?.color),
                      backgroundColor: chatData?.color,
                    }
              }
              className={`chat-message ${
                message.sender === "bot"
                  ? `bot-message ${chatData?.dark_mode && "bot-message--dark"}`
                  : "user-message"
              }`}
            >
              {message.sender === "bot" ? (
                <div
                  className={`bot-message-before ${
                    chatData?.dark_mode && "bot-message-before--dark"
                  }`}
                ></div>
              ) : (
                <div
                  style={{ backgroundColor: chatData?.color }}
                  className="user-message-before"
                ></div>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: message["content"].replace(/\n/g, "<br>"),
                }}
              ></div>
              {message.file &&
                chatData["show_links"] &&
                message.file.map((file, fileIndex) => (
                  <a
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={fileIndex}
                    className="file-tag-link"
                  >
                    <ClipIcon size={24} />{" "}
                    <div className="file-tag">{file.name}</div>
                  </a>
                ))}
            </div>
            {message.sender !== "bot" && (
              <img src="avatars/user.jpg" alt="User Face" />
            )}
          </div>
        ))}
        {isLoading && (
          <div className="chat-message-wrapper">
            <img
              style={{ border: `2px solid ${chatData?.color}` }}
              src="avatars/bot.jpg"
              alt="AI Face"
            />
            <div
              className={`chat-message bot-message ${
                chatData?.dark_mode && "bot-message--dark chat-message--dark"
              }`}
            >
              <div
                className={`bot-message-before ${
                  chatData?.dark_mode && "bot-message-before--dark"
                }`}
              ></div>
              <LoadingAnswer color={chatData?.color} />
            </div>
          </div>
        )}
      </div>
      <footer
        className={`chat-footer ${chatData?.dark_mode && "chat-footer--dark"}`}
      >
        <textarea
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          onKeyUp={handleTextAreaResize}
          placeholder={`${
            isLoading
              ? "Processando a resposta..."
              : "Escreva a sua pergunta..."
          }`}
          style={{
            color: getContrastYIQ(chatData?.dark_mode ? "#222222" : "#ffffff"),
          }}
          className={`chat-input ${chatData?.dark_mode && "chat-input--dark"}`}
          disabled={isLoading}
          rows={2}
        />
        <button
          onClick={handleSendMessage}
          className="send-button"
          style={{ backgroundColor: chatData?.color }}
          disabled={isLoading}
        >
          <SendIcon />
        </button>
      </footer>
    </div>
  );
}

export default ChatBot;
