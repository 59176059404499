import React from "react";

import "./index.css";

const LoadingAnswer = ({ color }) => {
  return (
    <div className="loading-answer">
      <div
        style={color ? { backgroundColor: color } : {}}
        className="loading-answer-1"
      ></div>
      <div
        style={color ? { backgroundColor: color } : {}}
        className="loading-answer-2"
      ></div>
      <div
        style={color ? { backgroundColor: color } : {}}
        className="loading-answer-3"
      ></div>
    </div>
  );
};

export default LoadingAnswer;
